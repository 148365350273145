import { ITicket } from "@/game/serving/interfaces/ITickets";
import UserInterface from "@/game/serving/states/userinterface";
import Setup from "@/helpers/Setup";
import Constants from "../../../configs/constants";
import Scaling from "../../../configs/scaling";
import Menu from "../../controllers/menu";
import HappinessMeter from "../happinessMeter";

export default class Ticket extends Phaser.GameObjects.Container {
  background: Phaser.GameObjects.Image;
  title: Phaser.GameObjects.Text;
  pagination: Phaser.GameObjects.Text;
  recipeImage: Phaser.GameObjects.Sprite;
  happinessMeter: HappinessMeter;
  particleLogo: Phaser.GameObjects.Particles.ParticleEmitterManager;
  particleLogosEmitter: Phaser.GameObjects.Particles.ParticleEmitter;
  menu: Menu;
  currentRecipe: number;
  recipes: string[];
  happinessTimerInSeconds: number;
  audioComplete: Phaser.Sound.BaseSound;
  audioFail: Phaser.Sound.BaseSound;
  audioProgress: Phaser.Sound.BaseSound;

  constructor(scene: Phaser.Scene, x: number, y: number, width: number, height: number) {
    super(scene, x, y);
    this.scene.add.existing(this);

    /** SETTINGS */
    this.menu = new Menu();
    this.currentRecipe = 0;
    this.recipes = [];
    this.happinessTimerInSeconds = 0;

    this.audioComplete = this.scene.sound.add('ticket_complete', { volume: 0.08 });
    this.audioFail = this.scene.sound.add('ticket_fail', { volume: 0.05 });
    this.audioProgress = this.scene.sound.add('ticket_progress', { volume: 0.1 });

    /** BACKGROUND */
    if (!this.scene.textures.exists('ticket_background')) {
      const graphic = this.scene.make.graphics({ x: 0, y: 0 });
      graphic.fillStyle(0xffffff, 1);
      graphic.fillRoundedRect(0, 0, width, height, Scaling.getPixelbyDPR(8));
      graphic.generateTexture('ticket_background', width, height);
    }
    this.background = this.scene.add.image(0, 0, 'ticket_background').setOrigin(0, 0);

    /** TEXT */
    this.title = this.scene.add.text(Scaling.getPixelbyDPR(8), Scaling.getPixelbyDPR(8), 'McRoyal Deluxe', {
      fontFamily: Constants.FONT_BOLD,
      fontSize: `${Scaling.getPixelbyDPR(12)}px`,
      align: "left",
      color: Setup.getValue('design.text.color'),
      wordWrap: { width: Scaling.getPixelbyDPR(50) }
    })
    this.pagination = this.scene.add.text(this.background.width - Scaling.getPixelbyDPR(8), Scaling.getPixelbyDPR(8), '0', {
      fontFamily: Constants.FONT_REGULAR,
      fontSize: `${Scaling.getPixelbyDPR(12)}px`,
      align: "right",
      color: Setup.getValue('design.text.color')
    }).setOrigin(1, 0);

    /** RECIPE IMAGE */
    this.recipeImage = this.scene.add.sprite(this.background.getBounds().centerX - Scaling.getPixelbyDPR(8), this.background.getBounds().centerY, 'ticket_burger_cheeseburger');

    /** HAPPINESS METER */
    this.happinessMeter = new HappinessMeter(this.scene, this.background.getBounds().centerX, this.background.getBounds().bottom - Scaling.getPixelbyDPR(12), {
      onFail: () => this.failTicket()
    });

    /** ARROW */
    const arrow = this.scene.add.image(this.recipeImage.getBottomCenter().x - Scaling.getPixelbyDPR(12), this.recipeImage.getBottomCenter().y - Scaling.getPixelbyDPR(12), 'ticket_arrow')

    /** PARTICLES */
    this.particleLogo = this.scene.add.particles('particle');
    this.particleLogosEmitter = this.particleLogo.createEmitter({
      speed: { min: -300, max: 300 },
      angle: { min: 0, max: 360 },
      scale: { start: 0.3, end: 0 },
      lifespan: 800,
      quantity: 100,
      frequency: -1
    });

    this.add([this.background, this.title, this.pagination, this.happinessMeter, this.particleLogo, this.recipeImage, arrow]);

    /** EVENTS */
    this.background.setInteractive();
    this.background.on('pointerdown', () => this.scene.events.emit('ticket_update', { ticket: this }));
  }

  new(data: ITicket) {
    this.reset();

    /** RECIPE: add new recipes and show first one */
    this.recipes = data.recipes;
    this.updateCurrentRecipe();

    /** HAPPINESS METER: start running new meter */
    this.happinessMeter.start(data.happinessTimerInSeconds * 1000);

    this.animateIn();
  }

  reset() {
    /** RECIPE: reset recipe array and current recipe */
    this.recipes = [];
    this.currentRecipe = 0;

    /** HAPPINESS METER: reset position and styling */
    this.happinessMeter.reset();
  }

  getCurrentRecipeId() {
    return this.recipes[this.currentRecipe];
  }

  updateProgress() {
    this.currentRecipe++;

    /** TICKET: complete ticket when all recipes are completed, otherwise show next recipe */
    if (this.currentRecipe == this.recipes.length) {
      this.completeTicket();
    } else {
      this.audioProgress.play();
      this.particleLogosEmitter.explode(80, this.recipeImage.x, this.recipeImage.y);

      this.scene.tweens.add({
        targets: this,
        scale: { from: 1, to: 1.1 },
        x: `+=${(this.background.width - this.background.width * 1.1) / 2}`,
        y: `+=${(this.background.height - this.background.height * 1.1) / 2}`,
        yoyo: true,
        ease: Phaser.Math.Easing.Cubic.InOut,
        duration: 200,
        onYoyo: () => this.updateCurrentRecipe()
      })
    }
  }

  updateCurrentRecipe() {
    const recipeId = this.getCurrentRecipeId();
    const recipe = this.menu.getRecipe(recipeId);

    /** TICKET: update text / pagination and image based upon current recipe */
    if (recipe) {
      this.title.setText(recipe.title);
      this.pagination.setText(`${this.currentRecipe + 1}/${this.recipes.length}`);
      this.recipeImage.setTexture(`ticket_burger_${recipeId}`);

    }
  }

  failTicket() {
    this.audioFail.play();

    this.scene.events.emit('ticket_fail');
    this.animateOut(true);
  }

  completeTicket() {
    this.audioComplete.play();

    /** TICKET: send event with current happiness meter status and length of order */
    const happiness = this.happinessMeter.getHappiness();
    this.scene.events.emit('ticket_complete', { orderLength: this.recipes.length, happiness: happiness });

    /** HAPPINESS METER: stop the tween event */
    this.happinessMeter.stop();

    this.animateOut(false);
  }

  animateIn() {
    this.scene.tweens.add({
      targets: this,
      y: { from: -this.background.height, to: 0 },
      ease: Phaser.Math.Easing.Back.Out,
      duration: 800,
      onStart: () => {
        this.setY(-this.background.height);
        this.setActive(true).setVisible(true);
      }
    })
  }

  animateOut(isFail: boolean) {
    if (!isFail) {
      this.scene.time.addEvent({ delay: 0, repeat: 30, callback: () => this.spawnEmoji(this.happinessMeter.progressbar.state) })
    }

    this.scene.tweens.add({
      targets: this,
      y: { from: 0, to: -this.background.height },
      ease: Phaser.Math.Easing.Back.In,
      delay: 350,
      duration: 800,
      onComplete: () => {
        this.setActive(false).setVisible(false);
      }
    })
  }

  spawnEmoji(type: string | number) {
    const UIScene = this.scene.scene.get('userinterface') as UserInterface;

    this.scene.game.events.emit('spawn_emoji', {
      type: type,
      current: {
        x: this.happinessMeter.emoji.getBounds().centerX,
        y: this.happinessMeter.emoji.getBounds().centerY
      },
      target: {
        x: UIScene.score.sprite.getBounds().centerX,
        y: UIScene.score.sprite.getBounds().centerY
      }
    });
  }
}
