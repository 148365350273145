import Phaser from "phaser";
import Constants from "../../../configs/constants";
import { IKitchenEquipment } from "../../../interfaces/IKitchen";
import KitchenEquipment from "./_base";

export default class Ingredient extends KitchenEquipment {
  constructor(scene: Phaser.Scene, settings: IKitchenEquipment){
    super(scene, settings);

  }

  onClick(): void {
    switch(this.name){
      case Constants.INGREDIENT_BURGER:
        this.scene.events.emit('place_burger');
      break;
      default:
        this.scene.events.emit('place_ingredient', {ingredient: this.name});
      break;
    }
  }
}