import Constants from "@/game/serving/configs/constants";
import Phaser from "phaser";
import Scaling from "../../../configs/scaling";
import { IKitchenEquipment } from "../../../interfaces/IKitchen";
import IngredientStack from "../../groups/IngredientStack";
import KitchenEquipment from "./_base";

export default class Tray extends KitchenEquipment {
  order: string[];
  packageSprite: Phaser.GameObjects.Sprite;
  particle: Phaser.GameObjects.Particles.ParticleEmitterManager;
  particleEmitter: Phaser.GameObjects.Particles.ParticleEmitter;
  isSelected: boolean;
  isReadyToRemove: boolean;
  removeResetEvent!:Phaser.Time.TimerEvent;
  ingredientStack: IngredientStack;
  id: number;
  clearTrayIcon:Phaser.GameObjects.Sprite;
  checkmarkIcon:Phaser.GameObjects.Sprite
  audioPlaceIngredient: Phaser.Sound.BaseSound[];
  audioPlaceSauce: Phaser.Sound.BaseSound[];
  audioPackage: Phaser.Sound.BaseSound;

  constructor(scene: Phaser.Scene, settings: IKitchenEquipment, id: number) {
    super(scene, settings);

    this.isSelected = false;
    this.isReadyToRemove = false;
    this.order = [];
    this.id = id;

    this.ingredientStack = new IngredientStack(scene);

    this.audioPackage = this.scene.sound.add('recipe_wrap', { volume: 0.08 });
    this.audioPlaceIngredient = [
      this.scene.sound.add('ingredient_drop1', { volume: 0.1 }),
      this.scene.sound.add('ingredient_drop4', { volume: 0.1 }),
      this.scene.sound.add('ingredient_drop5', { volume: 0.1 })
    ]

    this.audioPlaceSauce = [
      this.scene.sound.add('ingredient_drop2', { volume: 0.1 }),
      this.scene.sound.add('ingredient_drop3', { volume: 0.1 }),
    ]

    this.clearTrayIcon = this.scene.add.sprite(0, -Scaling.getPixelbyDPR(8), 'icon_clear_food').setVisible(false).setActive(false);
    this.checkmarkIcon = this.scene.add.sprite(0, Scaling.getPixelbyDPR(12), 'icon_checkmark').setVisible(false).setActive(false)
    this.packageSprite = this.scene.add.sprite(0, -Scaling.getPixelbyDPR(8), 'spritesheet_packages').setActive(false).setVisible(false);
    this.particle = this.scene.add.particles('particle');
    this.particleEmitter = this.particle.createEmitter({
      speed: { min: -300, max: 300 },
      angle: { min: 0, max: 360 },
      scale: { start: 0.3, end: 0 },
      lifespan: 600,
      quantity: 100,
      frequency: -1
    });

    this.add([...this.ingredientStack.getChildren(), this.particle, this.packageSprite, this.clearTrayIcon, this.checkmarkIcon]);
  }

  onClick() {
    /** REMOVE: Set the state to ready to remove, waiting for second click */
    if(!this.isReadyToRemove){
      this.isReadyToRemove = true;
      this.ingredientStack.setAlpha(0.5);
      this.packageSprite.setAlpha(0.5);
      this.clearTrayIcon.setActive(true).setVisible(true);

      /** REMOVE: Reset the remove state */
      this.removeResetEvent = this.scene.time.addEvent({ delay: 1500, callback: () => {
        this.isReadyToRemove = false;
        this.ingredientStack.setAlpha(1);
        this.packageSprite.setAlpha(1);
        this.clearTrayIcon.setActive(false).setVisible(false);
      }});
    }
    
    /** REMOVE: remove the current recipe from the tray */
    else if(this.isReadyToRemove){
      this.isReadyToRemove = false;
      this.setAlpha(1);
      
      this.removeResetEvent.remove();
      this.removeIngredients();
      this.reset();
    }
  }

  setSelected(state: boolean) {
    this.isSelected = state;
  }

  place(ingredient: string) {
    /** AUDIO: no audio when recipe is finished, different audio on sauce and non-sauce */
    if (ingredient !== `${Constants.INGREDIENT_BUN}_${Constants.INGREDIENT_BUN_TOP}`) {
      if (ingredient === (Constants.INGREDIENT_BIGMAC_SAUCE || Constants.INGREDIENT_KETCHUP || Constants.INGREDIENT_MAYONAISE || Constants.INGREDIENT_MUSTARD)) {
        const audio = Phaser.Math.RND.pick(this.audioPlaceSauce);
        audio.play();
      } else {
        const audio = Phaser.Math.RND.pick(this.audioPlaceIngredient);
        audio.play();
      }
    }

    /** INGREDIENT: place ingredient ontop of previous ingredient */
    this.ingredientStack.place(0, -Scaling.getPixelbyDPR(5) - Scaling.getPixelbyDPR(2 * this.order.length), ingredient);
    this.order.push(ingredient);
  }

  package(recipeId: string) {
    this.audioPackage.play();
    this.checkmarkIcon.setActive(true).setVisible(true)

    /** INGREDIENTS: Remove all ingredients to prepare for packaging*/
    this.removeIngredients();

    /** PACKAGE: set frame to show correct package and package angle */
    this.packageSprite.setFrame(`${recipeId}_${this.id}`);

    /** PACKAGE: tween package to bounce */
    this.scene.tweens.add({
      targets: this.packageSprite,
      y: { from: this.packageSprite.y - Scaling.getPixelbyDPR(20), to: this.packageSprite.y },
      ease: Phaser.Math.Easing.Bounce.Out,
      duration: 400,
      onStart: () => {
        this.packageSprite.setY(this.packageSprite.y - Scaling.getPixelbyDPR(20));
        this.packageSprite.setActive(true).setVisible(true);

        this.particleEmitter.explode(80, 0, 0);
      }
    })
  }

  removeIngredients() {
    this.ingredientStack.getMatching('active', true).forEach((ingredient: Phaser.GameObjects.Sprite) => {
      ingredient.setActive(false).setVisible(false);
    });
  }

  reset() {
    this.order = [];
    this.ingredientStack.setAlpha(1);
    this.packageSprite.setAlpha(1);    
    this.packageSprite.setActive(false).setVisible(false);
    this.clearTrayIcon.setActive(false).setVisible(false);
    this.checkmarkIcon.setActive(false).setVisible(false)
  }

}