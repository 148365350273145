import Constants from "@/game/serving/configs/constants";
import { IHappinessMeter } from "@/game/serving/interfaces/IHappinessMeter";

export default class HappinessMeter extends Phaser.GameObjects.Container {
  meter: Phaser.GameObjects.Image;
  progressbar: Phaser.GameObjects.Image;
  emoji:Phaser.GameObjects.Sprite;
  timerTween!: Phaser.Tweens.Tween | null;
  settings: IHappinessMeter;

  constructor(scene: Phaser.Scene, x: number, y: number, settings: IHappinessMeter) {
    super(scene, x, y);
    this.scene.add.existing(this);

    this.meter = this.scene.add.image(0, 0, 'happiness_empty');
    this.progressbar = this.scene.add.image(this.meter.getBounds().left, 0, 'happiness_filled').setOrigin(0, 0.5);
    this.emoji = this.scene.add.sprite(this.progressbar.getBounds().right, 0, 'spritesheet_emotions');

    this.settings = settings;

    this.add([this.meter, this.progressbar, this.emoji]);
  }

  setStateStyle(state: string){
    this.progressbar.setState(state);

    switch(state){
      case Constants.TICKET_HAPPY:
        this.progressbar.setTint(0x499D39);
        this.emoji.setFrame(0);
      break;
      case Constants.TICKET_ANNOYED:
        this.progressbar.setTint(0xFFAB00);
        this.emoji.setFrame(1);        
      break;
      case Constants.TICKET_UNHAPPY:
        this.progressbar.setTint(0xFF3C00);
        this.emoji.setFrame(2);        
      break;            
    }
  }

  reset(){
    this.setStateStyle(Constants.TICKET_HAPPY);
    this.progressbar.setScale(1);
    
    this.timerTween = null;
    this.emoji.setX(this.progressbar.x + (this.progressbar.width * this.progressbar.scaleX));
  }

  start(duration: number){
    this.timerTween = this.scene.tweens.add({
      targets: this.progressbar,
      scaleX: { from: 1, to: 0 },
      duration: duration,
      onUpdate: (tween) => {
        this.emoji.setX(this.progressbar.x + (this.progressbar.width * this.progressbar.scaleX));

        if (tween.progress >= 0.33 && tween.progress < 0.66 && this.progressbar.state !== Constants.TICKET_ANNOYED) {
          this.setStateStyle(Constants.TICKET_ANNOYED);
        }else if (tween.progress >= 0.66 && this.progressbar.state !== Constants.TICKET_UNHAPPY) {
          this.setStateStyle(Constants.TICKET_UNHAPPY);
        }
      },
      onComplete: () => this.settings.onFail()
    })
  }

  stop(){
    this.timerTween?.stop();
  }

  getHappiness(){
    return this.timerTween?.progress;
  }
}