import ITutorialConfigs from "../../interfaces/ITutorialConfigs";
import Constants from "../../configs/constants";
import Scaling from "../../configs/scaling";
import InteractiveVideo from "./interactiveVideo";
import Setup from "@/helpers/Setup";

export default class TutorialController extends Phaser.GameObjects.Container {
    index: number;
    videoComplete: boolean;
    buttonClickSound: Phaser.Sound.BaseSound;

    video!: InteractiveVideo
    subtitleText!: Phaser.GameObjects.Text
    introduction: Phaser.GameObjects.Container;
    walkthrough: Phaser.GameObjects.Container;

    constructor(scene: Phaser.Scene, x: number, y: number, configs: ITutorialConfigs) {
        super(scene, x, y)
        scene.add.existing(this)
        this.scene.scene.pause('game');

        /** SETTINGS */
        this.index = 0;
        this.videoComplete = false;

        /** GENERAL */
        this.setDepth(5);

        /** AUDIO */
        this.buttonClickSound = this.scene.sound.add('button_click', { volume: 0.1 });

        /** GAME OBJECTS: General */
        const overlay = scene.add.rectangle(0, scene.cameras.main.centerY, scene.cameras.main.width, scene.cameras.main.height, 0x00000, 0.8);
        this.add(overlay);

        /** GAME OBJECTS: Introduction */
        this.introduction = this.createIntroduction(configs);

        /** GAME OBJECTS: Walkthrough */
        this.walkthrough = this.createWalkthrough(configs);
        this.walkthrough.setActive(false).setVisible(false);

        /** CONTAINER: Add everything to this container */
        this.add([this.introduction, this.walkthrough]);

    }

    /**
    * create the first tutorial screen
    * @returns array of text elements
    */
    createIntroduction(config: ITutorialConfigs) {
        const container = this.scene.add.container(0, 0);

        const icon = this.scene.add.image(0, this.scene.cameras.main.centerX - Scaling.getPixelbyDPR(32), "tutorial_icon");
        const title = this.scene.add.text(0, icon.getBottomCenter().y + Scaling.getPixelbyDPR(12), config.introTitle, {
            fontSize: `${Scaling.getPixelbyDPR(24)}px`,
            fontFamily: Constants.FONT_BOLD,
            wordWrap: { width: this.scene.cameras.main.width / 1.5 },
            align: "center"
        }).setOrigin(0.5, 0);
        const description = this.scene.add.text(0, title.getBottomCenter().y + Scaling.getPixelbyDPR(12), config.introDescription, {
            fontSize: `${Scaling.getPixelbyDPR(16)}px`,
            fontFamily: Constants.FONT_REGULAR,
            align: "center",
            wordWrap: {width: this.scene.cameras.main.width - Scaling.getPixelbyDPR(5), useAdvancedWrap: true}
        }).setOrigin(0.5, 0);

        const button = this.scene.add.image(0, description.getBottomCenter().y + Scaling.getPixelbyDPR(32), "tutorial_startbutton");
        button.setInteractive().on("pointerdown", () => {
            this.buttonClickSound.play();
            this.startWalkthrough();
        }).setOrigin(0.5, 0)

        // Note: fontsize must be equal to y position otherwise it will be misaligned
        const buttonText = this.scene.add.text(button.x, button.y + Scaling.getPixelbyDPR(22) / 2, Setup.getValue(`tutorial.intro.start.${Setup.getLanguage()}.value` || Setup.getValue(`tutorial.intro.title.EN.value`)), {
            fontFamily: Constants.FONT_BOLD,
            fontSize: `${Scaling.getPixelbyDPR(22)}px`,
            color: "#FFFFFF",
        }).setOrigin(0.5, 0);

        container.add([icon, title, description, button, buttonText]);
        return container;
    }

    createWalkthrough(configs: ITutorialConfigs) {
        const container = this.scene.add.container(0, this.scene.cameras.main.centerY);

        this.video = new InteractiveVideo(this.scene, 0, 0, "tutorial_video", configs);
        this.video.setName("TutorialVideo");

        /** Create Gameobjects */
        const title = this.scene.add.text(0, -(this.scene.cameras.main.centerY) + Scaling.getPixelbyDPR(64), configs.title, {
            fontSize: `${Scaling.getPixelbyDPR(22)}px`,
            align: `center`,
            fontFamily: Constants.FONT_BOLD,
        }).setOrigin(0.5, 0);
        this.subtitleText = this.scene.add.text(0, this.video.getBottomCenter().y + Scaling.getPixelbyDPR(8), configs.points[0].tutorialText, {
            fontSize: `${Scaling.getPixelbyDPR(16)}px`,
            align: `center`,
            fontFamily: Constants.FONT_REGULAR,
            wordWrap: { width: this.video.displayWidth }
        }).setOrigin(0.5, 0);

        const button = this.scene.add.image(this.scene.cameras.main.width / 2 - Scaling.getPixelbyDPR(10), this.scene.cameras.main.height / 2 - Scaling.getPixelbyDPR(10), 'skip_button');
        button.setOrigin(1, 1)
        button.setInteractive().on("pointerdown", () => {
            this.buttonClickSound.play();
            this.finishWalkthrough();
        })

        // skiptext fallback
        const buttonText = this.scene.add.text(button.getLeftCenter().x - Scaling.getPixelbyDPR(2), button.getLeftCenter().y, Setup.getCopy('general.skip'), {
            fontSize: `${Scaling.getPixelbyDPR(12)}px`,
            fontFamily: Constants.FONT_BOLD,
        }).setOrigin(1, 0.5);

        /** Events */
        // on video marker complete
        this.video.on('complete', (video: Phaser.GameObjects.Video) => {
            this.videoComplete = true;
            video.setPaused();

        })

        // on click video update 
        this.video.on('pointerdown', () => {
            if (!configs.skipable) {
                if (!this.videoComplete) {
                    return false;
                }
            }

            this.nextWalkthroughStep(configs);
        })

        container.add([this.video, title, this.subtitleText, button, buttonText]);
        return container;
    }

    nextWalkthroughStep(configs: ITutorialConfigs) {
        if (!configs.skipable && !this.videoComplete) {
            return false;
        }

        /** WALKTHROUGH: Increment walkthrough index */
        this.index++;

        /** WALKTHROUGH: Stop if no next step is available */
        if (configs.points.length <= this.index) {
            this.video.clearMask();
            this.finishWalkthrough();
        }

        /** WALKTHORUGH: Continue if next step is available  */
        else {
            this.buttonClickSound.play();
            this.videoComplete = false;

            this.video.stop();
            this.video.playMarker(`tutorialPoint${this.index}`);
            this.subtitleText.setText(configs.points[this.index].tutorialText);
        }
    }

    toggleIntroductionVisibility(state: boolean) {
        this.introduction.setVisible(state).setActive(state);
    }

    toggleWalkthroughVisibility(state: boolean) {
        this.walkthrough.setVisible(state).setActive(state);
    }

    startWalkthrough() {
        this.toggleIntroductionVisibility(false);
        this.toggleWalkthroughVisibility(true);
    }

    finishWalkthrough() {
        this.setActive(false).setVisible(false);
        this.scene.scene.resume('game');
    }
}
