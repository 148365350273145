//@ts-ignore
import * as dat from "dat.gui";
import Phaser from "phaser";

export default class Debug extends Phaser.Scene {
  fps!: number;
  runtime!: number;

  constructor() {
    super({key: "debug"});
  }
  
  create() {
    this.fps = 0;
    this.runtime = 0;
  }

  update(delta: number, time: number) {
    this.fps = this.game.loop.actualFps;
    this.runtime = delta / 1000;
  }
}
