import Phaser from "phaser";
import { IKitchenEquipment } from "../../../interfaces/IKitchen";
import KitchenEquipment from "./_base";

export default class Sauce extends KitchenEquipment {
  constructor(scene: Phaser.Scene, settings: IKitchenEquipment){
    super(scene, settings);

  }

  onClick() {
    this.scene.events.emit('place_ingredient', {ingredient: this.name});
  }  
}