import Phaser from "phaser";
import * as Helpers from "../../configs/helpers";

export default class TimerCooking extends Phaser.GameObjects.Container {
  base: Phaser.GameObjects.Image;
  hand: Phaser.GameObjects.Sprite;
  event!: Phaser.Tweens.Tween;

  constructor(scene: Phaser.Scene, x: number, y: number){
    super(scene, x, y);
    this.scene.add.existing(this);

    this.base = this.scene.add.image(0, 0, 'timer_cooking').setOrigin(0.5, 1);
    this.hand = this.scene.add.sprite(0, 0, 'timer_cooking_hand').setOrigin(0.5, 1);

    this.add([this.base, this.hand]);
    this.hide();
  }

  start(duration: number, onUpdateCallback?: (tween: Phaser.Tweens.Tween) => void, onCompleteCallback?: () => void){
    this.event = this.scene.tweens.addCounter({
      from:0,
      to:1,
      duration:duration,
      onUpdate: (tween) => {
        const degree = Helpers.remapValue(tween.progress, 0, 1, -90, 90);
        this.hand.setAngle(degree);
        
        onUpdateCallback ? onUpdateCallback(tween) : null;
      },
      onComplete: () => {
        this.hide();

        onCompleteCallback ? onCompleteCallback() : null;
      }
    })    
  }

  stop(){
    if(this.event){
      this.event.stop();
      this.hide();
    }
  }

  show(){
    this.base.setActive(true).setVisible(true);
    this.hand.setActive(true).setVisible(true);    
  }

  hide(){
    this.base.setActive(false).setVisible(false);
    this.hand.setActive(false).setVisible(false);
    this.hand.setAngle(-90);
  }
}