import Scaling from "@/game/serving/configs/scaling";
import { ILives } from "@/game/serving/interfaces/ILives";

export default class Lives extends Phaser.GameObjects.Container {
  currentLives: number;
  audio: Phaser.Sound.BaseSound;

  constructor(scene: Phaser.Scene, x: number, y: number, settings: ILives) {
    super(scene, x, y);
    this.scene.add.existing(this);

    this.currentLives = settings.amount;
    this.audio = this.scene.sound.add('life_lost', { volume: 0.05 });

    for (let life = 0; life < settings.amount; life++) {
      const sprite = this.scene.add.sprite(0, 0, 'spritesheet_lives');
      sprite.setX(-Scaling.getPixelbyDPR(8 * (life + 1)) - (life * sprite.width));
      sprite.setOrigin(1, 0.5);

      this.add(sprite);
    }

    this.scene.game.events.on('update_lives', (data: any) => this.updateLives(data.mutate));
  }

  updateLives(mutation: number) {
    if (mutation < 0) { this.audio.play(); }
    this.currentLives = this.currentLives + mutation;

    this.list.forEach((gameobject: Phaser.GameObjects.GameObject, index: number) => {
      const sprite = gameobject as Phaser.GameObjects.Sprite;
      this.currentLives <= index ? sprite.setFrame(1) : sprite.setFrame(0);
    })
  }
}