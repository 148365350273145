import Scaling from "../../../configs/scaling";
import { ITicket, ITickets } from "../../../interfaces/ITickets"
import Ticket from "./ticket";

export default class Tickets extends Phaser.GameObjects.Container {
  constructor(scene: Phaser.Scene, x: number, y: number, settings: ITickets) {
    super(scene, x, y);
    this.scene.add.existing(this);

    this.createTickets(settings);
  }

  createTickets(settings: ITickets){
    for(let tickets = 0; tickets < settings.maxAmount; tickets++){
      const margin = Scaling.getPixelbyDPR(10);
      const width = Scaling.getPixelbyDPR(112) * (this.scene.cameras.main.width / Scaling.getPixelbyDPR(Scaling.GAME_BASE_WIDTH));
      const height = Scaling.getPixelbyDPR(180) * (this.scene.cameras.main.width / Scaling.getPixelbyDPR(Scaling.GAME_BASE_WIDTH));
      const x = margin + (tickets * width) + (tickets * margin);
      
      const ticket = new Ticket(this.scene, x, 0, width, height).setActive(false).setVisible(false);
      this.add(ticket);
    }
  }

  newTicket(settings: ITicket){
    const ticket = this.getFirst('active', false) as Ticket;

    if(ticket){
      ticket.new({
        recipes: settings.recipes,
        happinessTimerInSeconds: settings.happinessTimerInSeconds
      });
    }
  }
}