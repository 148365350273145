import Constants from "@/game/serving/configs/constants";
import Scaling from "@/game/serving/configs/scaling";
import Main from "@/game/serving/states/main";
import sessionSaveFile from "@/utils/game/SessionSaveFile";

export default class Score extends Phaser.GameObjects.Container {
  sprite: Phaser.GameObjects.Sprite;
  spriteAnimation!: Phaser.Tweens.Tween;
  score: Phaser.GameObjects.Text;
  tween!: Phaser.Tweens.Tween;
  baseX: number;

  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y);
    this.scene.add.existing(this);

    this.baseX = x;

    /** GAME OBJECTS */
    this.sprite = this.scene.add.sprite(0, 0, 'icon_emoji').setOrigin(0.5, 0.5);
    this.sprite.setX(this.sprite.width / 2);

    this.score = this.scene.add.text(this.sprite.getBounds().right + Scaling.getPixelbyDPR(12), 0, '0', {
      fontFamily: Constants.FONT_BOLD,
      fontSize: `${Scaling.getPixelbyDPR(20)}px`,
      align: "left",
      color: Constants.COLOR_WHITE_HEX
    }).setOrigin(0, 0.5);

    this.add([this.sprite, this.score]);

    /** SCORE: Recalculate position to align whole container to centerX */
    this.updatePosition();

    /** EVENTS */
    this.scene.game.events.on('update_score', (data: any) => this.updateScore(data.score));
  }

  updateScore(score: number) {
    const sceneGame = this.scene.scene.get('game') as Main;
    const previousScore = parseInt(`${sessionSaveFile.getValue("totalScore")}`) - score;
    const currentScore = parseInt(`${sessionSaveFile.getValue("totalScore")}`);

    if (this.tween) {
      this.tween.stop();
    }

    this.tween = this.scene.tweens.addCounter({
      from: previousScore,
      to: currentScore,
      duration: 400,
      onUpdate: (tween) => {
        const current = tween.data[0].current;
        if (current) {
          this.score.setText(Math.ceil(current).toString());
          this.updatePosition();
        }
      }
    })
  }

  updatePosition() {
    this.setX(this.baseX - ((this.sprite.width + this.score.width + Scaling.getPixelbyDPR(12)) / 2));
  }
}