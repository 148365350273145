import Phaser from "phaser";
import { IKitchenEquipment, IKitchenEquipmentGroup } from "../../../interfaces/IKitchen"
import KitchenEquipmentGroup from "./_base";

import Tray from "../kitchenEquipment/tray";

export default class Trays extends KitchenEquipmentGroup {
  constructor(scene: Phaser.Scene, x: number, y: number, settings: IKitchenEquipmentGroup){
    super(scene, x, y, settings);
    
    this.setName('trays');
    this.debugColor = 0x00ff00;
  }

  createEquipment(settings: IKitchenEquipment){
    this.id++;

    const tray = new Tray(this.scene, settings, this.id);
    return tray;
  }

  getSortedOnSelected(){
    const selectedTray = this.getFirst('isSelected', true);
    const unSelectedTrays = this.getAll('isSelected', false);
    const sortedTrays = [selectedTray, ...unSelectedTrays] as Tray[];

    return sortedTrays;
  }
}