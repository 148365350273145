import Phaser from "phaser";
import { IKitchenEquipment, IKitchenEquipmentGroup } from "../../../interfaces/IKitchen"
import KitchenEquipmentGroup from "./_base";

import Sauce from "../kitchenEquipment/sauce";

export default class Sauces extends KitchenEquipmentGroup {
  constructor(scene: Phaser.Scene, x: number, y: number, settings: IKitchenEquipmentGroup){
    super(scene, x, y, settings);
    
    this.debugColor = 0x1325ff;
  }

  createEquipment(settings: IKitchenEquipment){
    const sauce = new Sauce(this.scene, settings);
    return sauce;
  }
}