import Phaser from "phaser";
import { IKitchenEquipment, IKitchenEquipmentGroup } from "../../../interfaces/IKitchen"
import KitchenEquipment from "../kitchenEquipment/_base";

export default class KitchenEquipmentGroup extends Phaser.GameObjects.Container {
  debugColor: number;
  id: number;

  constructor(scene: Phaser.Scene, x: number, y: number, settings: IKitchenEquipmentGroup){
    super(scene, x, y);
    this.scene.add.existing(this);

    this.id = 0;
    this.debugColor = 0x00ff00;

    for(const item of settings.items){
      const equipment = this.createEquipment(item);
      this.add(equipment);
    }
  }

  createEquipment(settings: IKitchenEquipment): Phaser.GameObjects.Container{
    const equipment = this.scene.add.container(0, 0);
    return equipment;
  }

  debugHitbox(): void{
    this.iterate((equipment: KitchenEquipment) => {
      const graphic = this.scene.add.graphics();
      graphic.fillStyle(this.debugColor, 0.5);
      graphic.fillRect(equipment.zone.getBounds().left, equipment.zone.getBounds().top, equipment.zone.width, equipment.zone.height);    
      graphic.setDepth(2);
    })
  }
}