import { remapValue } from "@/game/serving/configs/helpers";
import Scaling from "@/game/serving/configs/scaling";
import Phaser from "phaser";

export default class Smoke extends Phaser.GameObjects.Container {
  smokeTween!: Phaser.Tweens.Tween;

  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y);
    this.scene.add.existing(this);

    const smoke1 = this.scene.add.sprite(-Scaling.getPixelbyDPR(12), -Scaling.getPixelbyDPR(15), 'grill_smoke').setAlpha(0);
    const smoke = this.scene.add.sprite(0, -Scaling.getPixelbyDPR(15), 'grill_smoke').setAlpha(0);
    const smoke2 = this.scene.add.sprite(Scaling.getPixelbyDPR(12), -Scaling.getPixelbyDPR(15), 'grill_smoke').setAlpha(0);

    this.add([smoke1, smoke, smoke2]);
  }

  start(){
    this.smokeTween = this.scene.tweens.add({
      targets: this.list,
      y: -Scaling.getPixelbyDPR(60),
      loop: -1,
      onUpdate: (tween) => {
        tween.targets.forEach((smoke: any) => {
          smoke.setAlpha(tween.progress <= 0.25 ? remapValue(tween.progress, 0, 0.25, 0, 1) : remapValue(tween.progress, 0.25, 0.5, 1, 0));
        })
      },
      duration: 5000
    })    
  }

  stop(){
    if(this.smokeTween){
      this.smokeTween.stop();

      this.iterate((smoke: Phaser.GameObjects.Sprite) => {
        smoke.setY(-Scaling.getPixelbyDPR(15));
        smoke.setAlpha(0);
      });
    }
  }
}