import Phaser from "phaser";
import Grills from "../containers/kitchenEquipmentGroup/grills";
import Grill from "../containers/kitchenEquipment/grill";
import Tray from "../containers/kitchenEquipment/tray";
import Trays from "../containers/kitchenEquipmentGroup/trays";
import Menu from "./menu";
import Constants from "../../configs/constants";
import Kitchen from "../containers/kitchen";
import Tickets from "../containers/tickets/tickets";
import Ticket from "../containers/tickets/ticket";
import { IRecipe } from "@/game/serving/interfaces/IRecipe";
import { remapValue } from "@/game/serving/configs/helpers";
import { ITicket } from "@/game/serving/interfaces/ITickets";

export default class Chef {
  scene: Phaser.Scene;
  menu: Menu;
  tickets: Tickets;

  constructor(scene: Phaser.Scene, kitchen: Kitchen, tickets: Tickets) {
    this.scene = scene;

    this.menu = new Menu();
    this.tickets = tickets;

    const trays = kitchen.getByName('trays') as Trays;
    const selectedTray = trays.getFirst('active', true) as Tray;
    selectedTray.setSelected(true);

    this.createKitchenTasks(kitchen);
  }

  createKitchenTasks(kitchen: Kitchen) {
    /** BURGER: Place on grill */
    this.scene.events.on('place_burger', () => {
      const grills = kitchen.getByName('grills') as Grills;
      const freeGrill = grills.getFirst('isCooking', false) as Grill;
      if (freeGrill) {
        freeGrill.placeBurger();
      }
    })

    /** BURGER: Throw a burger into the thrash can */
    this.scene.events.on('scrap_burger', () => {
      this.scene.game.events.emit('update_savefile_stats', { statistic: 'burnedBurgers', mutation: 1 });
    });

    /** BURGER: Add a well-done burger to order */
    this.scene.events.on('serve_burger', (data: any) => {
      const grill = data.grill;
      const trays = kitchen.getByName('trays') as Trays;
      const sortedTrays = trays.getSortedOnSelected();

      const tray = this.getTrayNeedingIngredient(sortedTrays, Constants.INGREDIENT_BURGER);
      if (tray) {
        this.placeIngredientOnTray(sortedTrays, tray, Constants.INGREDIENT_BURGER);
        grill.resetGrill();
      }
    });

    /** INGREDIENT: Add an ingredient to order */
    this.scene.events.on('place_ingredient', (data: any) => {
      const trays = kitchen.getByName('trays') as Trays;
      const sortedTrays = trays.getSortedOnSelected();
      const ingredient = data.ingredient;

      const tray = this.getTrayNeedingIngredient(sortedTrays, ingredient);
      if (tray) {
        this.placeIngredientOnTray(sortedTrays, tray, ingredient);
      }
    })

    /** TICKET: check if ticket can be updated in progression */
    this.scene.events.on('ticket_update', (data: any) => {
      const ticket = data.ticket as Ticket;
      const trays = kitchen.getByName('trays') as Trays;
      const sortedTrays = trays.getSortedOnSelected();

      this.checkProgressTicket(ticket, sortedTrays);
    })

    /** TICKET: Completed a ticket */
    this.scene.events.on('ticket_complete', (data: any) => {
      const happiness = remapValue(data.happiness, 1, 0, Constants.SCORE_HAPPINESS_MULTIPLY_MIN, Constants.SCORE_HAPPINESS_MULTIPLY_MAX);
      const score = (Constants.SCORE_BASE * data.orderLength) * happiness;
      
      console.log("order complete")
      this.scene.game.events.emit('update_score', { score: Math.ceil(score) });
      this.scene.game.events.emit('update_savefile_stats', { statistic: 'ordersFilled', mutation: 1 });
      this.scene.game.events.emit('update_savefile_stats', { statistic: 'happyCustomers', mutation: Math.ceil(score - (Constants.SCORE_BASE * data.orderLength)) });      
    })

    /** TICKET: Fail a ticket */
    this.scene.events.on('ticket_fail', () => {
      this.scene.game.events.emit('update_lives', { mutate: -1 });
    })    
  }

  getTrayNeedingIngredient(trays: Tray[], ingredient: string) {
    const targetedTray = trays.find((tray: Tray) => {
      /** TRAY: Buns get placed on empty trays immediately */
      if (tray.order.length === 0 && ingredient === Constants.INGREDIENT_BUN) {
        return true;
      }

      /** RECIPE: Check if current tray needs ingredient to continue its recipe */
      const canPlace = this.menu.getRecipeOfOrder(tray.order, ingredient);
      return canPlace.length ? true : false;
    })

    return targetedTray;
  }

  placeIngredientOnTray(trays: Tray[], tray: Tray, ingredient: string) {
    /** INGREDIENT: Get ingredient from recipe, since kitchen buns and burger have multiple states */
    const recipeIngredient = this.menu.getIngredient(tray.order, ingredient);
    tray.place(recipeIngredient);

    /** TRAY: Give tray that has ingredient on it priority, checking that tray first next ingredient */
    trays.forEach((tray: Tray) => tray.setSelected(false));
    tray.setSelected(true);

    /** RECIPE: check if recipe is complete, if so package recipe */
    const isOrderComplete = this.menu.isCompletedRecipe(tray.order);
    if(isOrderComplete){
      tray.package(isOrderComplete.id);
    }
  }

  checkProgressTicket(ticket: Ticket, trays: Tray[]) {
    const recipeId = ticket.getCurrentRecipeId();

    /** TICKET: check if ticket has an recipe on it (aka not completed) */
    if(recipeId){

      /** TRAY: Get tray with ticket required recipe */
      const traysWithCorrectRecipe = trays.filter((tray: Tray) => {
        const recipe = this.menu.isCompletedRecipe(tray.order);
        return recipe?.id === recipeId;
      })

      /** TRAY: update ticket progression and remove packaging */
      if(traysWithCorrectRecipe.length){
        const trayFirst = traysWithCorrectRecipe[0];

        trayFirst.reset();
        ticket.updateProgress();
      }
    }
  }
}