import Phaser from "phaser";
import { IKitchenEquipment, IKitchenEquipmentGroup } from "../../../interfaces/IKitchen"
import KitchenEquipmentGroup from "./_base";

import Grill from "../kitchenEquipment/grill";

export default class Grills extends KitchenEquipmentGroup {
  constructor(scene: Phaser.Scene, x: number, y: number, settings: IKitchenEquipmentGroup){
    super(scene, x, y, settings);
    
    this.setName('grills');
    this.debugColor = 0xffff00;

    this.scene.game.events.on('gameover', () => this.reset());
  }

  createEquipment(settings: IKitchenEquipment){
    const tray = new Grill(this.scene, settings);
    return tray;
  }

  reset(){
    this.iterate((grill: Grill) => {
      grill.resetGrill();
    })
  }
}