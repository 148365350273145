import Phaser from "phaser";
import { IKitchenEquipment, IKitchenEquipmentGroup } from "../../../interfaces/IKitchen"
import KitchenEquipmentGroup from "./_base";

import Ingredient from "../kitchenEquipment/ingredient";

export default class Ingredients extends KitchenEquipmentGroup {
  constructor(scene: Phaser.Scene, x: number, y: number, settings: IKitchenEquipmentGroup){
    super(scene, x, y, settings);
    
    this.debugColor = 0xff1212;
  }

  createEquipment(settings: IKitchenEquipment){
    const tray = new Ingredient(this.scene, settings);
    return tray;
  }
}