import Phaser from "phaser";

export default class Background extends Phaser.Scene {
  tileSpritePattern!: Phaser.GameObjects.TileSprite;
  music?: Phaser.Sound.BaseSound;

  constructor() {
    super({key: "background"});
  }
  
  create() {
    this.cameras.main.setBackgroundColor(0xFFFFFF);
    this.tileSpritePattern = this.add.tileSprite(0, 0, this.cameras.main.width, this.cameras.main.height, 'background_pattern').setOrigin(0, 0);
    
    // Notify that game has booted
    this.game.events.emit("booted");
  }
  
  setColor(color: number) {
    this.cameras.main.setBackgroundColor(color ? color : 0xFFFFFF);
  }
  
  playMusic() {
    this.stopMusic();
    
    //Create music instance & play
    this.music = this.sound.add("background_music");
    this.music.play({loop: true, volume: 0.05});
  }
  
  stopMusic() {
    if(this.music) {
      this.music.destroy();
    }
  }
  
  update(time: number, delta: number) {
    this.tileSpritePattern.tilePositionY -= 0.5;
    this.tileSpritePattern.tilePositionX += 0.5;
  }
}