import Phaser from "phaser";

import KitchenPlacementJson from "../data/kitchenEquipmentPlacement.json"
import Kitchen from "../components/containers/kitchen";
import Chef from "../components/controllers/chef";
import Tickets from "../components/containers/tickets/tickets";
import Scaling from "../configs/scaling";
import Kiosk from "../components/controllers/kiosk";
import Emojis from "../components/groups/emojis";
import SessionSaveFile from "@/utils/game/SessionSaveFile";
import { ScoreDataDefault, ScoreGameTypes } from "@/interfaces/ISessionFile";
import sessionSaveFile from "@/utils/game/SessionSaveFile";
import scoreController from "@/utils/game/ScoreController";
import Constants from "../configs/constants";
import CustomerHelper from "@/helpers/CustomerHelper";

export default class Main extends Phaser.Scene {
  isGameOver!: boolean;
  hasBoughtLoyalty!: boolean;

  constructor() {
    super({ key: "game" });
  }

  preload() {
    this.game.events.emit('start');

    /** USER INTERFACE */
    this.scene.launch('userinterface');
  }

  create() {
    /** GENERAL */
    this.hasBoughtLoyalty = false;
    this.isGameOver = false;
    SessionSaveFile.create({
      type: ScoreGameTypes.INFINITE,
      stats: ["burnedBurgers", "happyCustomers", "ordersFilled", "playtime", "lives"]
    })

    sessionSaveFile.updateValue("lives", Constants.LIVES)
    sessionSaveFile.updateValue("playtime", new Date().getTime())

    /** KITCHEN */
    const restaurant = this.add.image(this.cameras.main.centerX, 0, 'background_restaurant').setOrigin(0.5, 0).setDepth(1);
    const tickets = new Tickets(this, 0, Scaling.getPixelbyDPR(90), { maxAmount: 3 }).setDepth(2);
    const kitchen = new Kitchen(this, this.cameras.main.centerX, this.cameras.main.height, KitchenPlacementJson, false).setDepth(2);
    const logo = this.add.image(this.cameras.main.centerX, kitchen.getBounds().top + Scaling.getPixelbyDPR(40), 'background_logo').setOrigin(0.5, 1).setDepth(1);
    const screens = this.add.image(this.cameras.main.centerX, 0, 'background_screens').setOrigin(0.5, 0).setDepth(2);
    const emojis = new Emojis(this).setDepth(2);

    /** CHEF */
    const chef = new Chef(this, kitchen, tickets);

    /** KIOSK */
    const kiosk = new Kiosk(this, tickets);

    /** GENERAL EVENTS */
    this.game.events.on('spawn_emoji', (data: any) => emojis.spawn(data.current.x, data.current.y, data.target.x, data.target.y, data.type));
    this.game.events.on('update_savefile_stats', (data: any) => sessionSaveFile.incrementValue(data.statistic, data.mutation));
    this.game.events.on('update_score', (data: any) => sessionSaveFile.incrementValue(ScoreDataDefault.TOTALSCORE, data.score as number));
    this.game.events.on('update_lives', (data: any) => {
      if(!this.isGameOver){
        SessionSaveFile.incrementValue("lives", data.mutate)
        if (data.mutate < 0 && parseInt(`${sessionSaveFile.getValue("lives")}`) as number <= 0) {
          this.game.events.emit('gameover')
        }
      }
    });
    this.game.events.on('gameover', () => {
      console.log('gameover');
      
      this.isGameOver = true;
      this.time.addEvent({ delay: 1200, callback: () => {
          this.hasBoughtLoyalty ? this.gameOver() : this.halt();
        }
      })
    });
  }

  update() {

  }

  halt() {
    if (this.scene.isPaused()) {
      return;
    }
    this.scene.pause();

    /** LOYALTY: ask user to pay loyalty to keep playing */
    this.game.events.emit("halt", async (restore: any) => {
      if (restore > 0) {
        // Burn the points if available
        await CustomerHelper.handleAsyncRequest({type:'burnLoyaltyPoints',points:restore*100});    
        this.isGameOver = false;
        this.hasBoughtLoyalty = true;
        this.game.events.emit('update_lives', { mutate: restore });
        this.scene.resume();

      }
      else {
        this.gameOver();
      }
    });
  }

  gameOver() {
    this.shutdown();
  }

  shutdownEvents() {
    /** EVENTS: Global events affecting all scenes */
    this.game.events.off('spawn_emoji');
    this.game.events.off('update_savefile_stats');
    this.game.events.off('update_score');
    this.game.events.off('update_lives');
    this.game.events.off('gameover');

    /** EVENTS: Game scene only events */
    this.events.off('place_burger');
    this.events.off('scrap_burger');
    this.events.off('serve_burger');
    this.events.off('place_ingredient');
    this.events.off('ticket_update');
    this.events.off('ticket_complete');
    this.events.off('ticket_fail');
  }

  shutdown() {
    /** EVENTS: shutdown */
    this.shutdownEvents();

    /** SAVEFILE: update and send Vue event */
    /** SAVEFILE: update and send Vue event */
    const playTime = parseInt(`${sessionSaveFile.getValue("playtime")}`)
    sessionSaveFile.updateValue("playtime", (new Date().getTime() - playTime) / 1000 );
    const result = scoreController.getResult()

    this.game.events.emit('end', result);

    /** SCENES: cleanup */
    const UIScene = this.scene.get('userinterface');
    UIScene.scene.stop();

    this.scene.stop();
  }
}
