import Scaling from "../../configs/scaling"
import ITutorialConfigs from "../../interfaces/ITutorialConfigs"

export default class InteractiveVideo extends Phaser.GameObjects.Video {
    videoComplete: boolean

    constructor(scene: Phaser.Scene, x: number, y: number, key: string, configs: ITutorialConfigs) {
        super(scene, x, y, key)
        scene.add.existing(this)

        /** Video setting */
        this.setDisplaySize(this.displayWidth * 0.9, this.displayHeight * 0.9);
        this.setInteractive()

        /** Mask */
        const mask = this.scene.make.graphics({});
        mask.fillRoundedRect(this.scene.cameras.main.centerX - this.displayWidth / 2, this.scene.cameras.main.centerY - this.displayHeight / 2, this.displayWidth, this.displayHeight, Scaling.getPixelbyDPR(12));
        this.setMask(mask.createGeometryMask());

        /** Settings */
        this.videoComplete = configs.playOnStart;

        this.createMakers(configs);
    }

    /**
    * Makes all the markers that will be played on the video element
    * @param configs tutorial settings
    */
    createMakers(configs: ITutorialConfigs) {
        for (let i = 0; i < configs.points.length; i++) {
            this.addMarker(`tutorialPoint${i}`, configs.points[i].markIn, configs.points[i].markOut);
        }

        if (configs.playOnStart) {
            this.playMarker(`tutorialPoint${0}`);
        }
    }
}