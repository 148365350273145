import Setup from "@/helpers/Setup";
import Constants from "../../configs/constants";
import { arrayEquals } from "../../configs/helpers";
import { IBurgerSettings } from "../../interfaces/IBurgerSettings";
import { IRecipe } from "../../interfaces/IRecipe";

export default class Menu {
  menuTotal: IRecipe[] = [this.getBigMac(), this.getCheeseburger(), this.getMcRoyalDeluxe(), this.getQuarterPounder(), this.getHamburger(), this.getDoubleCheeseburger()]

  constructor() {}

  getFullMenu() {
    return this.menuTotal
  }

  getAvailableMenu() {
    const menuAvailable = [] as IRecipe[]
    const menuItems = Object.entries(Setup.data.settings.game.settings)

    menuItems.forEach(([key, value]) => {
      const settings = value as IBurgerSettings

      if(settings.available) {
        const recipe = this.menuTotal.find(recipe => recipe.id === key)
      
        if(recipe) {
          // loop through the title entries till it find the right language
          const burgerDataEntries = Object.entries(settings.title)
          burgerDataEntries.forEach(([key, nameData]: [string, any])=> {
            // find entry with the right langauge
            if(key === Setup.getLanguage())
              recipe.title = nameData.value;
          });

          menuAvailable.push(recipe);
        }
      }
    });
    // return new array
    return menuAvailable;
  }


  getRecipe(id: string){
    const menu = this.getAvailableMenu();
    const recipe = menu.find((recipe: IRecipe) => recipe.id === id);

    return recipe;
  }

  isCompletedRecipe(order: string[]){
    const menu = this.getAvailableMenu();
    const completedRecipe = menu.find((recipe: IRecipe) => {
      return arrayEquals(recipe.build, order);
    })

    return completedRecipe;
  }

  isPossibleRecipe(order: string[], recipe: IRecipe) {
    const recipeJoined = recipe.build.join();
    const orderJoined = order.join();

    return recipeJoined.includes(orderJoined);
  }

  getRecipeOfOrder(order: string[], ingredient: string) {
    const menu = this.getAvailableMenu();
    const recipes = menu.filter((recipe: IRecipe) => {
      return this.isPossibleRecipe(order, recipe) ? recipe : null;
    })

    const isRecipe = recipes.filter((recipe: IRecipe) => {
      return recipe.build[order.length] ? recipe.build[order.length].includes(ingredient) : false;
    });

    return isRecipe;
  }

  getIngredient(order: string[], ingredient: string) {
    const menu = this.getAvailableMenu();
    const recipes = menu.filter((recipe: IRecipe) => {
      return this.isPossibleRecipe(order, recipe) ? recipe : null;
    })

    const isRecipe = recipes.filter((recipe: IRecipe) => {
      return recipe.build[order.length] ? recipe.build[order.length].includes(ingredient) : false;
    });

    return isRecipe[0].build[order.length];
  }

  getBigMac(): IRecipe {
    return {
      id: Constants.RECIPE_BIGMAC,
      title: 'Big Mac (R)',
      buildTime:90,
      build: [
        `${Constants.INGREDIENT_BUN}_${Constants.INGREDIENT_BUN_BOTTOM}`,
        Constants.INGREDIENT_BIGMAC_SAUCE,
        Constants.INGREDIENT_SALAD,
        Constants.INGREDIENT_CHEESE,
        `${Constants.INGREDIENT_BURGER}_${Constants.INGREDIENT_BURGER_DONE}`,
        Constants.INGREDIENT_ONION,
        `${Constants.INGREDIENT_BUN}_${Constants.INGREDIENT_BUN_BOTTOM}`,
        Constants.INGREDIENT_BIGMAC_SAUCE,
        Constants.INGREDIENT_SALAD,
        Constants.INGREDIENT_PICKLE,
        `${Constants.INGREDIENT_BURGER}_${Constants.INGREDIENT_BURGER_DONE}`,
        Constants.INGREDIENT_ONION,
        `${Constants.INGREDIENT_BUN}_${Constants.INGREDIENT_BUN_TOP}`
      ]
    }
  }

  getCheeseburger(): IRecipe {
    return {
      id: Constants.RECIPE_CHEESEBURGER,
      title: 'Cheese burger',
      buildTime:60,
      build: [
        `${Constants.INGREDIENT_BUN}_${Constants.INGREDIENT_BUN_BOTTOM}`,
        Constants.INGREDIENT_ONION,
        `${Constants.INGREDIENT_BURGER}_${Constants.INGREDIENT_BURGER_DONE}`,
        Constants.INGREDIENT_CHEESE,
        Constants.INGREDIENT_PICKLE,
        Constants.INGREDIENT_KETCHUP,
        Constants.INGREDIENT_MUSTARD,
        `${Constants.INGREDIENT_BUN}_${Constants.INGREDIENT_BUN_TOP}`
      ]
    }
  }

  getQuarterPounder(): IRecipe {
    return {
      id: Constants.RECIPE_QUATREPOUNDER,
      title: 'Cuarto de Libra',
      buildTime:70,
      build: [
        `${Constants.INGREDIENT_BUN}_${Constants.INGREDIENT_BUN_BOTTOM}`,
        Constants.INGREDIENT_MUSTARD,
        Constants.INGREDIENT_KETCHUP,
        Constants.INGREDIENT_ONION,
        Constants.INGREDIENT_PICKLE,
        Constants.INGREDIENT_CHEESE,
        `${Constants.INGREDIENT_BURGER}_${Constants.INGREDIENT_BURGER_DONE}`,
        Constants.INGREDIENT_CHEESE,
        `${Constants.INGREDIENT_BUN}_${Constants.INGREDIENT_BUN_TOP}`
      ]
    }
  }

  getMcRoyalDeluxe(): IRecipe {
    return {
      id: Constants.RECIPE_MCROYALDELUXE,
      title: 'McRoyal Deluxe (R)',
      buildTime:60,
      build: [
        `${Constants.INGREDIENT_BUN}_${Constants.INGREDIENT_BUN_BOTTOM}`,
        Constants.INGREDIENT_MAYONAISE,
        Constants.INGREDIENT_SALAD,
        Constants.INGREDIENT_TOMATO,
        Constants.INGREDIENT_CHEESE,
        `${Constants.INGREDIENT_BURGER}_${Constants.INGREDIENT_BURGER_DONE}`,
        `${Constants.INGREDIENT_BUN}_${Constants.INGREDIENT_BUN_TOP}`
      ]
    }
  }

  getHamburger(): IRecipe {
    return {
      id: Constants.RECIPE_HAMBURGER,
      title: 'hamburger',
      buildTime:60,
      build: [
        `${Constants.INGREDIENT_BUN}_${Constants.INGREDIENT_BUN_BOTTOM}`,
        Constants.INGREDIENT_ONION,
        `${Constants.INGREDIENT_BURGER}_${Constants.INGREDIENT_BURGER_DONE}`,
        Constants.INGREDIENT_PICKLE,
        Constants.INGREDIENT_KETCHUP,
        Constants.INGREDIENT_MUSTARD,
        `${Constants.INGREDIENT_BUN}_${Constants.INGREDIENT_BUN_TOP}`
      ]
    }
  }

  getDoubleCheeseburger(): IRecipe {
    return {
      id: Constants.RECIPE_DOUBLECHEESEBURGER,
      title: 'double cheese burger',
      buildTime: 70,
      build: [
        `${Constants.INGREDIENT_BUN}_${Constants.INGREDIENT_BUN_BOTTOM}`,
        Constants.INGREDIENT_ONION,
        `${Constants.INGREDIENT_BURGER}_${Constants.INGREDIENT_BURGER_DONE}`,
        Constants.INGREDIENT_CHEESE,
        `${Constants.INGREDIENT_BURGER}_${Constants.INGREDIENT_BURGER_DONE}`,
        Constants.INGREDIENT_CHEESE,
        Constants.INGREDIENT_PICKLE,
        Constants.INGREDIENT_KETCHUP,
        Constants.INGREDIENT_MUSTARD,
        `${Constants.INGREDIENT_BUN}_${Constants.INGREDIENT_BUN_TOP}`
      ]
    }
  }
}
