//@ts-ignore
import WebFont from "webfontloader";
import Phaser from "phaser";
import Constants from "../configs/constants";
import Scaling from "../configs/scaling";
import * as Helpers from "../configs/helpers";

export default class Boot extends Phaser.Scene {
  constructor() {
    super({key: "boot"});
  }
  
  preload() {
    // Load Fonts //
    this.load.on("complete", () => {
      WebFont.load({
        custom: {
          families: [Constants.FONT_BOLD, Constants.FONT_REGULAR]
        },
        active: () => this.booted(true),
        inactive: () => this.booted(false)
      });
    });
    
    // Load  //
    this.load.image("background_pattern", Scaling.ImagePath("pattern-background", "png"));
    this.load.image("logo", Scaling.ImagePath("logo", "png"));
  }
  
  booted(fontsLoaded: boolean) {
    Helpers.sendTracking({'event': 'boot'});
    
    // Fonts: Loaded check //
    if (!fontsLoaded) {
      return console.error("fonts failed to load...");
    }
    
    // Scene: launch parallel background scene //
    // NOTE: When not using background, you can place the booted event here
    this.scene.launch('background');
    this.scene.stop();
  }
}
