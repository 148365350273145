export default class Constants {

    /** FONTS */
    static FONT_BOLD = "font_bold";
    static FONT_REGULAR = "font_regular";

    /** COLORS */
    static COLOR_YELLOW_HEX = '#FFB300';
    static COLOR_YELLOW_INT = 0xFFB300;
    static COLOR_BLACK_HEX = '#000000';
    static COLOR_WHITE_HEX = '#FFFFFF';
    static COLOR_WHITE_INT = 0xffffff;

    /** GENERAL */
    static LIVES = 3;
    static SCORE_BASE = 5;
    static SCORE_HAPPINESS_MULTIPLY_MIN = 1;
    static SCORE_HAPPINESS_MULTIPLY_MAX = 3;
    static BURGER_COOK_TIME = 2 * 1000;
    static BURGER_BURNING_TIME = 4 * 1000;

    /** RECIPES */
    static RECIPE_CHEESEBURGER = "cheeseburger";
    static RECIPE_BIGMAC = "bigmac";
    static RECIPE_QUATREPOUNDER = "quaterpounder";
    static RECIPE_MCROYALDELUXE = "mcroyaldeluxe";
    static RECIPE_HAMBURGER = "hamburger"
    static RECIPE_DOUBLECHEESEBURGER = "doublecheeseburger"

    /** KIOSK */
    static KIOSK_TICKET_DELAY_MIN = 4 * 1000;
    static KIOSK_TICKET_DELAY_MAX = 8 * 1000;
    static KIOSK_TICKET_SIZE_MAX = 3;

    /** TICKET STATE */
    static TICKET_HAPPY = "happy";
    static TICKET_ANNOYED = "annoyed";
    static TICKET_UNHAPPY = "unhappy";    

    /** INGREDIENTS */
    static INGREDIENT_BURGER = "burger";
    static INGREDIENT_BUN = "bun";
    static INGREDIENT_CHEESE = "cheese";
    static INGREDIENT_SALAD = "salad";
    static INGREDIENT_ONION = "onion";
    static INGREDIENT_PICKLE = "pickle";
    static INGREDIENT_TOMATO = "tomato";
    static INGREDIENT_MAYONAISE = "mayonaise";
    static INGREDIENT_MUSTARD = "mustard";
    static INGREDIENT_KETCHUP = "ketchup";
    static INGREDIENT_BIGMAC_SAUCE = "bigmacsauce";

    /** INGREDIENT STATE */
    static INGREDIENT_BURGER_RAW = "raw";
    static INGREDIENT_BURGER_BURNED = "burned";
    static INGREDIENT_BURGER_DONE = "done";
    static INGREDIENT_BUN_TOP = "top";
    static INGREDIENT_BUN_BOTTOM = "bottom";
}