import Setup from "@/helpers/Setup";
import Constants from "../../configs/constants";
import Scaling from "../../configs/scaling";
import Main from "../../states/main";

export default class ButtonPause extends Phaser.GameObjects.Container {
  audio: Phaser.Sound.BaseSound | undefined;
  popupContainer: Phaser.GameObjects.Container
  pauseButton: Phaser.GameObjects.Image


  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y);
    this.scene.add.existing(this);

    this.pauseButton = scene.add.image(Scaling.getPixelbyDPR(48), Scaling.getPixelbyDPR(32), "button_pause").setName("pauseButton")
    this.add(this.pauseButton)
    this.audio = this.scene.sound.add('button_click', { volume: 0.1 })

    /** Popupbox */
    this.popupContainer = scene.add.container(scene.cameras.main.centerX, scene.cameras.main.centerY)
    const popupBox = this.scene.add.rectangle(0, 0, this.scene.cameras.main.width * 0.9, this.scene.cameras.main.height * 0.4, 0xFFFFFF)
    const textElements = this.createText(popupBox)
    const buttons = this.createButtons(popupBox)

    this.popupContainer.add([popupBox, ...textElements, ...buttons]).setActive(false).setVisible(false)

    this.pauseButton.setInteractive().on('pointerup', () => {

      if (!this.scene.scene.isPaused('game')) {
        this.pause()
      }
    })
  }

  pause() {
    this.audio?.play()
    this.popupContainer.setVisible(true).setActive(true)
    this.scene.scene.pause('game');
    this.pauseButton.setTexture('button_play');
  }

  resume() {
    this.audio?.play()
    this.popupContainer.setVisible(false).setActive(false)
    this.scene.scene.resume('game');
    this.pauseButton.setTexture('button_pause');
  }

  createText(popupBox: Phaser.GameObjects.Rectangle) {
    const popupTitle = this.scene.add.text(popupBox.getTopCenter().x, popupBox.getTopCenter().y + Scaling.getPixelbyDPR(30), Setup.getCopy("general.pause.title"), {
      color: "#000000",
      fontFamily: Constants.FONT_BOLD,
      fontSize: `${Scaling.getPixelbyDPR(18)}px`
    }).setOrigin(0.5, 0)
    const popupText = this.scene.add.text(popupTitle.getTopCenter().x, popupTitle.getTopCenter().y + Scaling.getPixelbyDPR(40), Setup.getCopy("general.pause.description"), {
      color: "#000000",
      fontFamily: Constants.FONT_REGULAR,
      fontSize: `${Scaling.getPixelbyDPR(16)}px`,
      wordWrap: { width: popupBox.width * 0.9 },
      align: "center"
    }).setOrigin(0.5, 0)

    return [popupTitle, popupText]
  }

  createButtons(popupBox: Phaser.GameObjects.Rectangle) {
    const stopButton = this.scene.add.image(popupBox.getBottomCenter().x, popupBox.getBottomCenter().y - Scaling.getPixelbyDPR(20), "stop-button").setOrigin(0.5, 1).setInteractive()
    const stopText = this.scene.add.text(stopButton.getCenter().x, stopButton.getCenter().y, Setup.getCopy("general.pause.option2"),     { 
      fontFamily: Constants.FONT_BOLD,
      fontSize: `${Scaling.getPixelbyDPR(18)}px`,
      color: "0x000000"
    }).setOrigin(0.5, 0.5)

    const resumeButton = this.scene.add.image(stopButton.getTopCenter().x, stopButton.getTopCenter().y - Scaling.getPixelbyDPR(10), "resume-button").setOrigin(0.5, 1).setInteractive()
    const resumeTest = this.scene.add.text(resumeButton.getCenter().x, resumeButton.getCenter().y, Setup.getCopy("general.pause.option1"), 
    { 
      fontFamily: Constants.FONT_BOLD,
      fontSize: `${Scaling.getPixelbyDPR(18)}px`
    }).setOrigin(0.5, 0.5)

    stopButton.on("pointerdown", () => {
      this.resume()
      const gameScene = this.scene.scene.get("game") as Main
      
      gameScene.game.events.emit('gameover')
    })

    resumeButton.on("pointerdown", () => {
      this.resume()
    })

    return [stopButton, stopText, resumeButton, resumeTest]
  }
}