import Scaling from "@/game/serving/configs/scaling";
import Phaser from "phaser";

export default class IngredientStack extends Phaser.GameObjects.Group {
  constructor(scene: Phaser.Scene) {
    super(scene);

    this.createMultiple({
      key: 'spritesheet_ingredients',
      active: false,
      visible: false,
      quantity: 20
    })

  }

  place(x: number, y: number, type: string) {
    const ingredient = this.getFirstDead() as Phaser.GameObjects.Sprite;

    ingredient.setScale(0.7);
    ingredient.setFrame(type);
    ingredient.setX(x);

    ingredient.setActive(true).setVisible(true);

    this.scene.tweens.add({
      targets: ingredient,
      y: { from: y - Scaling.getPixelbyDPR(8), to: y },
      ease: Phaser.Math.Easing.Bounce.Out,
      duration: 250,
      onStart: () => {
        ingredient.setY(y - Scaling.getPixelbyDPR(8));
      }
    })  
  }
}