import Phaser from "phaser";
import Scaling from "../../configs/scaling";
import { IKitchen, IKitchenEquipmentGroup } from "../../interfaces/IKitchen"

import Grills from "./kitchenEquipmentGroup/grills";
import Ingredients from "./kitchenEquipmentGroup/ingredients";
import Trays from "./kitchenEquipmentGroup/trays";
import Sauces from "./kitchenEquipmentGroup/sauces";

export default class Kitchen extends Phaser.GameObjects.Container {
  constructor(scene: Phaser.Scene, x: number, y: number, settings: IKitchen, debug?: boolean) {
    super(scene, x, y);
    this.scene.add.existing(this);

    const background = this.scene.add.image(0, 0, 'background_counter').setOrigin(0.5, 1);

    const grills = this.createGrills(background, settings.grills);
    const trays = this.createTrays(background, settings.trays);
    const ingredients = this.createIngredients(background, settings.ingredients);
    const sauces = this.createSauces(background, settings.sauces);

    this.add([background, grills, trays, ingredients, sauces]);

    if (debug) {
      this.debugHitboxes();
    }
  }

  createGrills(parent: Phaser.GameObjects.Image, settings: IKitchenEquipmentGroup) {
    const grills = new Grills(this.scene, 0, parent.getTopCenter().y + Scaling.getPixelbyDPR(105), settings);
    return grills;
  }

  createTrays(parent: Phaser.GameObjects.Image, settings: IKitchenEquipmentGroup) {
    const trays = new Trays(this.scene, 0, parent.getTopCenter().y + Scaling.getPixelbyDPR(30), settings);
    return trays;
  }

  createIngredients(parent: Phaser.GameObjects.Image, settings: IKitchenEquipmentGroup) {
    const ingredients = new Ingredients(this.scene, 0, parent.getTopCenter().y, settings);
    return ingredients;
  }  

  createSauces(parent: Phaser.GameObjects.Image, settings: IKitchenEquipmentGroup) {
    const ingredients = new Sauces(this.scene, 0, parent.getTopCenter().y, settings);
    return ingredients;
  }    

  debugHitboxes() {
    this.iterate((child: any) => {
      if (typeof child.debugHitbox === 'function') {
        child.debugHitbox();
      }
    })
  }
}