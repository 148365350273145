import Setup from "@/helpers/Setup";
import Phaser from "phaser";
import Lives from "../components/containers/lives";
import Score from "../components/containers/score";
import TutorialController from "../components/containers/tutorialController";
import ButtonPause from "../components/sprites/buttonPause";
import Constants from "../configs/constants";

import Scaling from "../configs/scaling";
import { ItutorialPoint } from "../interfaces/ITutorialConfigs";

export default class UserInterface extends Phaser.Scene {
  buttonPause!: ButtonPause;
  lives!: Lives;
  score!: Score;

  constructor() {
    super({ key: "userinterface" });
  }

  create() {
    this.buttonPause = new ButtonPause(this, 0, 0);
    this.lives = new Lives(this, this.cameras.main.width - Scaling.getPixelbyDPR(50), Scaling.getPixelbyDPR(32), { amount: Constants.LIVES });
    this.score = new Score(this, this.cameras.main.centerX - Scaling.getPixelbyDPR(80), Scaling.getPixelbyDPR(32));

    const tutorialPoints: ItutorialPoint[] = []

    for (let i = 1; i < 6; i++) {
      tutorialPoints.push({
        markIn: Setup.getValue(`tutorial.step${i}.start.value`) as number,
        markOut: Setup.getValue(`tutorial.step${i}.end.value`) as number,
        tutorialText: Setup.getValue(`tutorial.step${i}.subtitles.${Setup.getLanguage()}.value`)
      })
    }

    const tutorial = new TutorialController(this, this.cameras.main.centerX, 0, {
      title: Setup.getValue(`tutorial.title.${Setup.getLanguage()}.value`),
      introTitle: Setup.getValue(`tutorial.intro.title.${Setup.getLanguage()}.value`),
      introDescription: Setup.getValue(`tutorial.intro.description.${Setup.getLanguage()}.value`),
      playOnStart: true,
      skipable: true,
      points: tutorialPoints
    });
  }

}
