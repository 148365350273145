import Constants from "@/game/serving/configs/constants";
import { randomIntBetween } from "@/game/serving/configs/helpers";
import { IRecipe } from "@/game/serving/interfaces/IRecipe";
import { ITrial } from "@/game/serving/interfaces/ITrial";
import Tickets from "../containers/tickets/tickets";
import Menu from "./menu";

export default class Kiosk {
  scene: Phaser.Scene;
  tickets: Tickets;
  menu: Menu;
  difficulty: number;
  maxDifficulty: number;
  maxTickets: number;
  trial: ITrial;

  constructor(scene: Phaser.Scene, tickets: Tickets) {
    this.scene = scene;

    this.tickets = tickets;
    this.menu = new Menu();

    this.difficulty = 1;
    this.maxDifficulty = 3;
    this.maxTickets = 3;
    
    this.trial = {
      complete:false,
      maxAmount:1,
      current:0
    }

    this.createTasks();
    this.createTrial();
  }

  createTrial() {
    const tasks = this.menu.getAvailableMenu();
    if (tasks.length === 0) {
      console.error('there are no burgers available\nhave you selected any in gameplatform')
    }

    this.scene.time.addEvent({
      delay: 1000,
      repeat: this.trial.maxAmount - 1,
      callback: () => {
        this.tickets.newTicket({
          recipes: [this.menu.getCheeseburger().id],
          happinessTimerInSeconds: 9999
        });
      }
    })      
  }

  createTasks() {
    /** TICKET: Create new ticket, on completed ticket with delay */
    this.scene.events.on('ticket_complete', () => {
      if(this.trial.complete){
        this.scene.time.addEvent({
          delay: randomIntBetween(Constants.KIOSK_TICKET_DELAY_MIN, Constants.KIOSK_TICKET_DELAY_MAX),
          callback: () => this.createNewTicket()
        })
      }else {
        this.updateTrial();
      }
    });

    /** TICKET: Create new ticket, on failed ticket with delay */
    this.scene.events.on('ticket_fail', () => {
      this.scene.time.addEvent({
        delay: randomIntBetween(Constants.KIOSK_TICKET_DELAY_MIN, Constants.KIOSK_TICKET_DELAY_MAX),
        callback: () => this.createNewTicket()
      })
    });    
  }

  createNewTicket() {
    const recipes = this.getRecipes();
    const recipesIdArray = recipes.reduce((acc, value) => [...acc, value.id], [] as string[]);
    const happiness = this.getCustomerHappiness(recipes);

    this.tickets.newTicket({
      recipes: recipesIdArray,
      happinessTimerInSeconds: happiness
    })    
  }

  getRecipes(){
    const recipes = [];

    while(recipes.length !== Constants.KIOSK_TICKET_SIZE_MAX && (!recipes.length || Math.random() < (0.5 * this.difficulty))){
      const recipe = Phaser.Math.RND.weightedPick(this.menu.getAvailableMenu());
      recipes.push(recipe);
    }

    return recipes;
  }

  getCustomerHappiness(recipes: IRecipe[]){
    const ticketBuildTime = recipes.reduce((acc, value) => acc + value.buildTime, 0);
    const happiness = ticketBuildTime / this.difficulty;

    return happiness;
  }

  updateTrial(){
    this.trial.current++;
    this.trial.complete = this.trial.current == this.trial.maxAmount ? true : false;
    
    /** TICKET: Spawn up to (MAX) amount of tickets over time
     * NEEDS UPDATING, REPLACE WITH SPAWN TIMER THAT CHECKS IF TICKET CAN SPAWN AFTER EACH X SECONDS
     */
    if(this.trial.complete){
      this.scene.time.addEvent({delay:1500, callback: () => this.createNewTicket()});
      this.scene.time.addEvent({
        delay:60 * 1000,
        repeat:this.maxTickets - 2,
        callback: () => {
          this.createNewTicket();
        }
      })
    }
  }  
}