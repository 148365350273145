import Setup from "@/helpers/Setup";
import Phaser from "phaser";
import Constants from "../configs/constants";
import Scaling from "../configs/scaling";
import Background from "./background";

export default class Load extends Phaser.Scene {
  constructor() {
    super({ key: "load" });
  }

  preload() {
    const logoContainer = this.add.container(this.cameras.main.centerX, this.cameras.main.centerY);

    /** LOGO */
    const logo = this.add.image(0, 0, 'logo').setOrigin(0.5, 0);
    const logoBounds = logo.getBounds();

    /** LOADER */
    const loaderWidth = Scaling.getPixelbyDPR(245);

    if (!this.textures.exists('loader_base')) {
      const graphic = this.make.graphics({ x: 0, y: 0 });
      graphic.fillStyle(Phaser.Display.Color.HexStringToColor(Setup.getColor('primary')).color, 0.5);
      graphic.fillRect(0, 0, loaderWidth, Scaling.getPixelbyDPR(12));
      graphic.generateTexture('loader_base', loaderWidth, Scaling.getPixelbyDPR(12));
    }
    const loaderBase = this.add.image(logoBounds.centerX, logoBounds.bottom + Scaling.getPixelbyDPR(75), 'loader_base').setOrigin(0.5, 0);
    const loaderLoaded = this.add.graphics({
      x: loaderBase.getBounds().centerX - ((loaderWidth) / 2),
      y: loaderBase.getBounds().top
    });
    loaderLoaded.fillStyle(Phaser.Display.Color.HexStringToColor(Setup.getColor('primary')).color, 1);
    loaderLoaded.fillRect(0, 0, 0, Scaling.getPixelbyDPR(12));

    logoContainer.add([logo, loaderBase, loaderLoaded]);
    logoContainer.setY(logoContainer.y - (logoContainer.getBounds().height / 2))

    /** PRELOAD: backgrounds */
    this.load.image("background_screens", Scaling.ImagePath("sprite-scene-top-z3", "png"));
    this.load.image("background_counter", Scaling.ImagePath("sprite-scene-z2", "png"));
    this.load.image("background_restaurant", Scaling.ImagePath("sprite-scene-z1", "png"));
    this.load.image("background_logo", Scaling.ImagePath("sprite-scene-background-logo", "png"));

    /** PRELOAD: spritesheets */
    this.load.atlas("spritesheet_ingredients", Scaling.ImagePath('spritesheet_ingredients', 'png'), Scaling.ImagePath('spritesheet_ingredients_atlas', 'json'));
    this.load.atlas("spritesheet_packages", Scaling.ImagePath('packages', 'png'), Scaling.ImagePath('packages_atlas', 'json'));
    this.load.spritesheet("spritesheet_lives", Scaling.ImagePath('spritesheet-lifes-24x40', 'png'), { frameWidth: Scaling.getPixelbyDPR(24), frameHeight: Scaling.getPixelbyDPR(40) });
    this.load.spritesheet("spritesheet_emotions", Scaling.ImagePath('spritesheet-emotion-icons-16x16', 'png'), { frameWidth: Scaling.getPixelbyDPR(16), frameHeight: Scaling.getPixelbyDPR(16) });

    /** PRELOAD: sprites */
    this.load.image("grill_smoke", Scaling.ImagePath("asset-smoke", "png"));
    this.load.image("grill_gradient", Scaling.ImagePath("sprite-grill-gradient", "png"));
    this.load.image("happiness_empty", Scaling.ImagePath("meter-empty", "png"));
    this.load.image("happiness_filled", Scaling.ImagePath("meter-fill", "png"));
    this.load.image("timer_cooking", Scaling.ImagePath("sprite-heat-meter-base", "png"));
    this.load.image("timer_cooking_hand", Scaling.ImagePath("sprite-heat-meter-wijzer", "png"));
    this.load.image("button_pause", Scaling.ImagePath("btn-pause", "png"));
    this.load.image("button_play", Scaling.ImagePath("btn-play", "png"));
    this.load.image("icon_emoji", Scaling.ImagePath("icon-emoji", "png"));
    this.load.image("icon_clear_food", Scaling.ImagePath("icon-clear-food", "png"));
    this.load.image("icon_checkmark", Scaling.ImagePath("icon-checkmark", "png"))
    this.load.image("particle", Scaling.ImagePath("logo-particle", "png"));

    this.load.image(`ticket_arrow`, Scaling.ImagePath("icon-arrow-up", "png"))
    this.load.image(`ticket_burger_${Constants.RECIPE_BIGMAC}`, Scaling.ImagePath("sprite-burger-bigmac", "png"));
    this.load.image(`ticket_burger_${Constants.RECIPE_CHEESEBURGER}`, Scaling.ImagePath("sprite-burger-cheeseburger", "png"));
    this.load.image(`ticket_burger_${Constants.RECIPE_MCROYALDELUXE}`, Scaling.ImagePath("sprite-burger-mcroyal", "png"));
    this.load.image(`ticket_burger_${Constants.RECIPE_QUATREPOUNDER}`, Scaling.ImagePath("sprite-burger-quaterpounder", "png"));
    this.load.image(`ticket_burger_${Constants.RECIPE_HAMBURGER}`, Scaling.ImagePath("sprite-burger-hamburger", "png"));
    this.load.image(`ticket_burger_${Constants.RECIPE_DOUBLECHEESEBURGER}`, Scaling.ImagePath("sprite-burger-doublecheeseburger", "png"));

    /** PRELOAD: audio */
    this.load.audio("background_music", ["sounds/games/serving/background.mp3"]);
    this.load.audio("button_click", ["sounds/games/serving/button-click.mp3"]);
    this.load.audio("coin", ["sounds/games/serving/coin.mp3"]);
    this.load.audio("grill_burger", ["sounds/games/serving/grill-burger.mp3"]);
    this.load.audio("ingredient_drop1", ["sounds/games/serving/ingredient-drop1.mp3"]);
    this.load.audio("ingredient_drop2", ["sounds/games/serving/ingredient-drop2.mp3"]);
    this.load.audio("ingredient_drop3", ["sounds/games/serving/ingredient-drop3.mp3"]);
    this.load.audio("ingredient_drop4", ["sounds/games/serving/ingredient-drop4.mp3"]);
    this.load.audio("ingredient_drop5", ["sounds/games/serving/ingredient-drop5.mp3"]);
    this.load.audio("life_lost", ["sounds/games/serving/life-lost.mp3"]);
    this.load.audio("recipe_wrap", ["sounds/games/serving/recipe-wrap.mp3"]);
    this.load.audio("ticket_complete", ["sounds/games/serving/ticket-complete.mp3"]);
    this.load.audio("ticket_fail", ["sounds/games/serving/ticket-fail.mp3"]);
    this.load.audio("ticket_progress", ["sounds/games/serving/ticket-progress.mp3"]);
    this.load.audio("ticket_progress2", ["sounds/games/serving/ticket-progress2.mp3"]);

    // Tutorial
    this.load.video('tutorial_video', Scaling.ImagePath("tutorial", "mp4"));
    this.load.image('skip_button', Scaling.ImagePath("skip-button", "png"))
    this.load.image('tutorial_icon', Scaling.ImagePath("tutorial-icon", "png"))
    this.load.image('tutorial_startbutton', Scaling.ImagePath("tutorial-button", "png"))

    // Pause
    this.load.image("stop-button", Scaling.ImagePath("stopButton", "png"))
    this.load.image("resume-button", Scaling.ImagePath("resumeButton", "png"))

    /** PRELOAD: progression */
    this.load.on("progress", (value: number) => {
      loaderLoaded.clear();
      loaderLoaded.fillStyle(Phaser.Display.Color.HexStringToColor(Setup.getColor('primary')).color, 1);
      loaderLoaded.fillRect(0, 0, (loaderWidth * value), Scaling.getPixelbyDPR(12));
    });

    /** PRELOAD: complete */
    this.load.on("complete", () => {
      this.time.addEvent({
        delay: 500,
        callback: () => {
          const proceedText = this.add.text(this.cameras.main.centerX, logoContainer.getBounds().bottom + Scaling.getPixelbyDPR(12), Setup.getCopy('general.touchStart'), {
            fixedWidth: this.scene.systems.canvas.width,
            align: "center",
            fontFamily: Constants.FONT_BOLD,
            fontSize: `${Scaling.getPixelbyDPR(18)}px`,
            color: Setup.getColor('text')
          }).setOrigin(0.5, 0);

          this.tweens.add({
            targets: proceedText,
            scale: { to: 1.1, from: 1 },
            yoyo: true,
            repeat: -1,
            duration: 1000,
            ease: "Power1"
          });

          this.input.on("pointerup", () => {
            this.input.off("pointerup");
            
            // Sounds: Play //
            const audio_button = this.sound.add('button_click', { volume: 0.1 });
            audio_button.play();
            
            const backgroundScene = this.scene.get("background") as Background;
            console.log(backgroundScene)
            if (backgroundScene) {
              backgroundScene.playMusic();
            }

            // Game event: Loaded //
            this.game.events.emit("loaded");
          });

        }
      });
    });
  }
}
