import Constants from "@/game/serving/configs/constants";
import { remapValue } from "@/game/serving/configs/helpers";
import Scaling from "@/game/serving/configs/scaling";

export default class Emojis extends Phaser.GameObjects.Group {
  audio:Phaser.Sound.BaseSound;

  constructor(scene: Phaser.Scene) {
    super(scene);

    this.createMultiple({
      key: 'spritesheet_emotions',
      active: false,
      visible: false,
      quantity: 120
    })

    this.audio = this.scene.sound.add('coin', {volume:0.08});
  }

  getFrameOfType(type: string) {
    switch (type) {
      case Constants.TICKET_HAPPY:
        return 0;
      case Constants.TICKET_ANNOYED:
        return 1;
      case Constants.TICKET_UNHAPPY:
        return 2;
      default:
        return 0;
    }
  }

  spawn(x: number, y: number, targetX: number, targetY: number, type: string) {
    const sprite = this.getFirstDead() as Phaser.GameObjects.Sprite;

    if(!this.audio.isPlaying){
      this.audio.play();
    }

    sprite.setFrame(this.getFrameOfType(type));
    sprite.setScale(1);
    sprite.setPosition(x, y);
    sprite.setActive(true).setVisible(true);

    const startVector = new Phaser.Math.Vector2(x, y);
    const startVectorControl = new Phaser.Math.Vector2(Phaser.Math.RND.between(x - Scaling.getPixelbyDPR(40), x + Scaling.getPixelbyDPR(40)), y);
    const endVectorControl = new Phaser.Math.Vector2(Phaser.Math.RND.between(targetX - Scaling.getPixelbyDPR(40), targetX + Scaling.getPixelbyDPR(40)), targetY);
    const endVector = new Phaser.Math.Vector2(targetX, targetY);

    const bezierCurve = new Phaser.Curves.CubicBezier(startVector, startVectorControl, endVectorControl, endVector);

    this.scene.tweens.addCounter({
      from: 0,
      to: 1,
      duration: 1000,
      ease: Phaser.Math.Easing.Sine.InOut,
      onUpdate: (tween) => {
        const current = tween.data[0].current;

        if(current){
          const position = bezierCurve.getPoint(current);
          sprite.setPosition(position.x, position.y);
          sprite.setScale(remapValue(current, 0, 1, 1, 0.5));
        }
      },
      onComplete:() => {        
        sprite.setActive(false).setVisible(false);
      }
    })
  }
}