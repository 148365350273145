import Phaser from "phaser";
import Scaling from "../../../configs/scaling";
import { IKitchenEquipment } from "../../../interfaces/IKitchen";

export default class KitchenEquipment extends Phaser.GameObjects.Container {
  zone: Phaser.GameObjects.Zone;

  constructor(scene: Phaser.Scene, settings: IKitchenEquipment){
    super(scene, Scaling.getPixelbyDPR(settings.coords.x || 0), Scaling.getPixelbyDPR(settings.coords.y || 0));
    this.scene.add.existing(this);

    this.setName(settings.name);

    this.zone = this.scene.add.zone(0, 0, Scaling.getPixelbyDPR(settings.size.width), Scaling.getPixelbyDPR(settings.size.height));
    this.zone.setInteractive();
    this.zone.on('pointerdown', () => this.onClick());

    this.add(this.zone);
  }

  onClick(){
    console.log(this.name);
  }
}