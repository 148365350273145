import Phaser from "phaser";
import Constants from "../../../configs/constants";
import { IKitchenEquipment } from "../../../interfaces/IKitchen";
import Smoke from "../smoke";
import TimerCooking from "../timerCooking";
import KitchenEquipment from "./_base";

export default class Grill extends KitchenEquipment {
  isCooking: boolean;
  heat: Phaser.GameObjects.Sprite;
  heatTween!: Phaser.Tweens.Tween;
  burger: Phaser.GameObjects.Sprite;
  smoke: Smoke;
  timer: TimerCooking;
  timerBurned!: Phaser.Time.TimerEvent;
  timerCooking!: Phaser.Tweens.Tween;
  audio: Phaser.Sound.BaseSound | undefined;

  constructor(scene: Phaser.Scene, settings: IKitchenEquipment) {
    super(scene, settings);

    this.isCooking = false;
    this.heat = this.scene.add.sprite(0, 0, 'grill_gradient').setActive(false).setVisible(false);
    this.burger = this.scene.add.sprite(0, 0, 'spritesheet_ingredients').setActive(false).setVisible(false).setScale(0.8);
    this.smoke = new Smoke(this.scene, 0, 0).setActive(false).setVisible(false);
    this.timer = new TimerCooking(this.scene, 0, this.getBounds().top).setScale(0.8);

    this.audio = this.scene.sound.add('grill_burger', { volume: 0.15, loop: true })

    this.add([this.heat, this.burger, this.timer, this.smoke]);
  }

  onClick(): void {
    if (this.isCooking) {
      switch (this.burger.state) {
        case Constants.INGREDIENT_BURGER_DONE:
          this.serveBurger()
          break;
        case Constants.INGREDIENT_BURGER_BURNED:
          this.scrapBurger();
          break;
      }
    }
  }

  placeBurger(): void {
    this.isCooking = true;

    this.burger.setState(Constants.INGREDIENT_BURGER_RAW);
    this.burger.setFrame(`${Constants.INGREDIENT_BURGER}_${Constants.INGREDIENT_BURGER_RAW}`);
    this.burger.setActive(true).setVisible(true);

    this.heat.setActive(true).setVisible(true);
    this.heatTween = this.scene.tweens.add({
      targets: this.heat,
      scale: { from: 1, to: 1.2 },
      duration: 1000,
      ease: Phaser.Math.Easing.Linear,
      yoyo: true,
      repeat: -1
    })

    this.setTimer();

    this.audio?.play();
  }

  setTimer() {
    const totalDuration = Constants.BURGER_COOK_TIME + Constants.BURGER_BURNING_TIME;

    this.timer.show();
    this.timer.start(totalDuration, (tween) => {
      const burgerProgressDone = Constants.BURGER_COOK_TIME / totalDuration;
      if (this.burger.state != Constants.INGREDIENT_BURGER_DONE && tween.progress >= burgerProgressDone) {
        this.setBurgerState(Constants.INGREDIENT_BURGER_DONE);
      }
    }, () => {
      this.setBurgerState(Constants.INGREDIENT_BURGER_BURNED);
      this.smoke.setActive(true).setVisible(true);
      this.smoke.start();
    })
  }

  setBurgerState(state: string) {
    this.burger.setState(state);
    this.burger.setFrame(`${Constants.INGREDIENT_BURGER}_${state}`);
  }

  serveBurger() {
    this.scene.events.emit('serve_burger', { grill: this });
  }

  scrapBurger() {
    this.scene.events.emit('scrap_burger');
    this.resetGrill();
  }

  resetGrill() {
    this.isCooking = false;
    this.timer.stop();

    this.burger.setActive(false).setVisible(false);
    this.smoke.setActive(false).setVisible(false);
    this.smoke.stop();

    this.heat.setActive(false).setVisible(false);

    if (this.heatTween) { this.heatTween.stop(); }
    if (this.audio) { this.audio.stop(); }
  }
}