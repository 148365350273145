import Phaser from "phaser";

import Boot from "./states/boot";
import Background from "./states/background";
import Load from "./states/load";
import Main from "./states/main";
import UserInterface from "./states/userinterface";
import Debug from "./states/debug";
import Scaling from "./configs/scaling";

export default class Game {
  private gameInstance: Phaser.Game;
  private loaded = false;

  constructor(parentWidth: number, parentHeight: number) {

    // Adjust game width/height if resolution ratio is different from base //
    Scaling.updateResolutionRatio(parentWidth, parentHeight);

    //Construct config
    const config: Phaser.Types.Core.GameConfig = {
      type: Phaser.AUTO,
      width: Scaling.GAME_WIDTH,
      height: Scaling.GAME_HEIGHT,
      scale: {
        parent: "game",
        zoom: 1 / Scaling.DPR,
        width: Scaling.GAME_WIDTH * Scaling.DPR,
        height: Scaling.GAME_HEIGHT * Scaling.DPR,
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH
      },
      physics: {
        default: "arcade",
        arcade: {
          debug: false
        }
      },
      fps: {
        target: 60,
        forceSetTimeOut: false
      },
      render: {
        antialias: true,
        roundPixels: true,
        pixelArt: false
      },
      plugins: {
        global: []
      },
      scene: [
        Boot,
        Background,
        Load,
        Main,
        UserInterface,
        Debug
      ]
    };

    //Create and run game
    this.gameInstance = new Phaser.Game(config);
  }
  startGame(data?: any) {
    const scene = this.gameInstance.scene.getScene("game");
    if (scene) {
      if (data) {
        if (data.toMap) {
          scene.scene.start('game', { toMap: data.toMap });
        }
        if (data.toNextLevel) {
          scene.scene.start('game', { level: data.toNextLevel });
        }
      } else {
        scene.scene.start('game')
      }
    }
  }

  //Events
  setEventListener(name: string, listener?: Function) {
    this.removeEventListener(name);

    //Only set, if actually provided
    if (listener) {
      this.gameInstance.events.on(name, listener);
    }
  }

  removeEventListener(name: string) {
    this.gameInstance.events.off(name);
  }

  //Start/stop scene by key
  startScene(key: string) {
    const scene = this.gameInstance.scene.getScene(key);
    if (scene) {
      scene.scene.start();
    }
  }

  stopScene(key: string) {
    const scene = this.gameInstance.scene.getScene(key);
    if (scene) {
      scene.scene.stop();
    }
  }

  //Load helpers
  loadGame() {
    if (!this.loaded) {
      this.startScene("load");
      this.loaded = true;
    }
  }

  endLoad() {
    if (this.loaded) {
      this.stopScene("load");
    }
  }

  //Setters
  setSaveData(data: any) {
    this.gameInstance.registry.set("saveData", data);
  }

  setBackgroundColor(color: number) {
    const scene = this.gameInstance.scene.getScene("background") as any;
    if (scene) {
      scene.setColor(color);
    }
  }

  setMutedStatus(state: boolean) {
    this.gameInstance.sound.mute = state
  }

  //Used to destroy phaser instance
  destroy() {
    this.gameInstance.destroy(true);
  }


}